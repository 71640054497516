<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button-group class="vg_mr_8">
          <el-button :type="activeTableName === 'sh' ? 'primary' : ''" size="small" @click="switchTable('sh')">S H</el-button>
          <el-button :type="activeTableName === 'hk' ? 'primary' : ''" size="small" @click="switchTable('hk')">H K</el-button>
        </el-button-group>
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="收款开始日期"
          end-placeholder="收款结束日期"
        />
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange2"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="录入开始日期"
          end-placeholder="录入结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getTableData"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
      </div>
      <search-table
        v-if="activeTableName === 'sh'"
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'segi_id'"
        :columns="shTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="row => dbClickJp(row, 'se', 140)"
        @getTableData="getTableData"
        :show-summary="true"
        :summariesColumns="['segi_total', 'scon_rmbtotal']"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
      <search-table
        v-if="activeTableName === 'hk'"
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'hegi_id'"
        :columns="hkTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="row => dbClickJp(row, 'he', 142)"
        @getTableData="getTableData"
        :show-summary="true"
        :summariesColumns="['hegi_total']"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, postNoCatch } from '@api/request';
import { segiAPI } from '@api/modules/segi';
import { stffAPI } from '@/api/modules/staff';
import { cpttAPI } from '@api/modules/comptitle';
import { custAPI } from '@/api/modules/cust';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { hkTableProperties, shTableProperties } from '@/views/FinanceManagement/SegiManage/segi';
import { hegiAPI } from '@api/modules/hegi';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SegiList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      activeTableName: 'sh',
      dateRange: [],
      dateRange2: [],
      shTableProperties: shTableProperties,
      hkTableProperties: hkTableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      stffUserList: [],
      cpttList: [],
      custOptionList: []
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/segi_edit' || '/hegi_edit' === from.path) {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getTableData();
      // this.getStffUser();
      // this.getCptt();
      // this.initCust();
    },
    async getTableData() {
      await (this.loadFlag = true);
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime ? startTime / 1000 : null;
      searchForm.endTime = endTime ? endTime / 1000 : null;
      let [startTime2, endTime2] = this.dateRange2 || [];
      searchForm.startTime2 = startTime2 ? startTime2 / 1000 : null;
      searchForm.endTime2 = endTime2 ? endTime2 / 1000 : null;
      getNoCatch(this.activeTableName === 'sh' ? segiAPI.getSegis : hegiAPI.getHegis, searchForm).then(({ data }) => {
        setTimeout(() => (this.loadFlag = false), 800);
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
      });
    },
    // 获取客户
    initCust() {
      get(custAPI.getAllCustsV1, { status: 2 })
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(err => console.error(err));
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    // 获取人员信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 新增
    addRow() {
      let { path, permId } = this.activeTableName === 'sh' ? { path: 'se', permId: 140 } : { path: 'he', permId: 142 };
      this.jump(`/${path}gi_add?perm_id=${permId}`);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row, region, permId) {
      this.jump(`/${region}gi_edit`, {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row[`${region}gi_id`],
            cust: row['cust_abbr'],
            type: region
          })
        )
      });
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let ids;
          let activeNameBoolean = this.activeTableName === 'sh';
          if (activeNameBoolean) {
            ids = Array.from(this.multiSelection, ({ segi_id }) => segi_id);
          } else {
            ids = Array.from(this.multiSelection, ({ hegi_id }) => hegi_id);
          }
          let postBody = [{ segi_id_list: ids }, { hegi_id_list: ids }];
          postNoCatch(activeNameBoolean ? segiAPI.deleteSegiByIds : hegiAPI.deleteHegiByIds, postBody[activeNameBoolean ? 0 : 1]).then(({ data }) => {
            this.$message.success('删除成功!');
            this.refresh();
          });
        })
        .catch(() => this.$message.info('取消删除!'));
    },
    refresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    async switchTable(pageName) {
      await (this.activeTableName = pageName);
      await this.$refs.multiTable.resetFields();
      await this.getTableData();
      this.multiSelection = [];
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
